import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="w-full">
        <div className="fondovideoo ">
          <div className="z-10 relative w-4/5 mx-auto pt-[450px] pb-[50px] md:pb-[150px] md:pt-[360px]  ">
            <div className="w-full flex flex-col justify-center items-center lg:px-20">
              <h1 className="text-white text-center">
                {rpdata?.dbSlogan?.[0].slogan}
              </h1>
              <p className="text-white text-center lg:px-36">{rpdata?.dbValues?.[0].description}</p>
              <div className="flex justify-center md:block">
                <ButtonContent />
              </div>
            </div>
            <div className="w-1/2"></div>
          </div>
        </div>

      </div>
    </>
  );
}

export default HeroSection;
